import { useState } from 'react';

interface FormData {
  [key: string]: string;
}

type useInputChangeFunction = (
  initialState?: FormData
) => [FormData, (e: React.ChangeEvent<HTMLInputElement>) => void, (fillWith?: string | null | undefined) => void];

export const useInputChange: useInputChangeFunction = (initialState = {}) => {
  const [input, setInput] = useState(initialState);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  const clearForm = (fillWith: string | null | undefined = '') =>
    setInput(
      Object.entries(input).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: fillWith,
        };
      }, {})
    );

  return [input, handleInputChange, clearForm];
};
