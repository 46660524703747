import { Container, createStyles, Icon, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      transition: '0.2s',
      borderRadius: '50%',
      borderWidth: theme.spacing(0.75),
      borderStyle: 'solid',
      borderColor: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '72px',
      height: '72px',
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    hover: {
      background: theme.palette.common.white,
      border: theme.palette.primary.main,
      borderWidth: '3px',
      borderStyle: 'solid',
      color: theme.palette.primary.main,
    },
    icon: {
      fontSize: '2.2em',
    },
  })
);

export interface IconProps {
  iconName: string;
  hover?: boolean;
  className?: string;
}

export const IconBox: React.FC<IconProps> = ({ iconName, hover, className }) => {
  const classes = useStyles();
  return (
    <Container className={`${className} ${classes.container} ${hover ? classes.hover : ''}`}>
      <Icon className={classes.icon}>{iconName}</Icon>
    </Container>
  );
};
