import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import aboutImage from '../../../images/filler/sensors-01.jpg';
import { Anchor } from '../../shared/Anchor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      padding: theme.spacing(0, 0, 2, 0),
      textTransform: 'uppercase',
    },
    gridRoot: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 7, 0),
      },
    },
    graphicContainer: {
      textAlign: 'right',
    },
    graphic: {
      width: '100%',
      maxWidth: '400px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '1000px',
      },
    },
    description: {
      flexGrow: 1,
      color: theme.palette.common.white,
      padding: theme.spacing(0.5, 0, 0, 2),
    },
    descriptionTitle: {
      textTransform: 'uppercase',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  })
);

export const Sensors: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Anchor name="about-us" />
      <Container>
        <Grid container className={classes.gridRoot} spacing={2} alignItems="center" direction="row-reverse">
          <Grid item sm={12} md={6} className={classes.graphicContainer}>
            <img src={aboutImage} alt="about us" className={classes.graphic}></img>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container justify="center" spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4" className={classes.header}>
                  Sensors
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography className={classes.description}>
                  From flow meters monitoring 1000's bbls/day to temperature probes detecting the slightest degree of
                  change, <span className={classes.noWrap}>Tri Technology</span> is committed to providing our customers
                  the best available sensors for the application. We provide any peripheral your job requires; pumps,
                  flow meters, pressure sensors, thermocouples, fluid level monitoring sensors, bulk tank sensors,
                  analog or digital, and much more.{' '}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
