import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import splashImage from '../../images/backgrounds/hero-bg5.jpg';
import { Anchor } from '../shared/Anchor';
import { Splash } from '../shared/Splash';
import Benefits from './Benefits';
import CaseStudies from './CaseStudies';
import FAQs from './FAQs';
import Summary from './Summary';
import Videos from './Videos';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideTitleList: {},
    sideTitleListItem: {
      // padding: theme.spacing(5, 0),
      // width: '100%',
    },
  })
);

const Apcotane: React.FC = () => {
  return (
    <>
      <Anchor name="home" />
      <Splash
        image={splashImage}
        darken={0.5}
        header="Apcotane"
        subHeader="H2S Mitigation game changer"
        buttonText="Learn more"
        anchor="/services/apcotane#summary"
      />
      <Summary />
      <Benefits />
      <CaseStudies />
      <FAQs />
      <Videos />
    </>
  );
};

export default Apcotane;
