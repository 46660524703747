import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useInputChange } from '../../../hooks';

const { REACT_APP_API_URL } = process.env;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactForm: {
      flexGrow: 1,
    },
    input: {
      width: '100%',
    },
    textArea: {
      width: '100%',
      fontSize: theme.typography.fontSize,
    },
    submitButton: {
      color: theme.palette.common.white,
      margin: theme.spacing(3, 0),
      height: theme.spacing(5),
    },
    submittedMessage: {},
  })
);

export const ContactForm: React.FC = () => {
  const classes = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData, clearFormData] = useInputChange({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    setSending(true);
    // await axios.post(`${REACT_APP_API_URL}/contact-us`, formData);
    await axios.post(`${REACT_APP_API_URL}/contact-us`, formData);
    setSubmitted(true);
    setSending(false);
  };

  const sendAnotherOnClick = () => {
    setSubmitted(!submitted);
    clearFormData('');
  };

  if (submitted) {
    return (
      <>
        <Typography className={classes.submittedMessage}>
          Your request was sent successfully. <br />
          We'll get back to you as soon as possible.
          <br />
          <br />
          Thank you! <br />
          <Button variant="contained" color="primary" className={classes.submitButton} onClick={sendAnotherOnClick}>
            Send Another
          </Button>
        </Typography>
      </>
    );
  } else {
    return (
      <Grid item sm={12} md={8} className={classes.contactForm}>
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextValidator
                name="name"
                label="Your Name"
                variant="filled"
                className={classes.input}
                value={formData.name}
                onChange={setFormData}
                validators={['required']}
                errorMessages={['Name is required']}
              ></TextValidator>
            </Grid>
            <Grid item>
              <TextValidator
                name="email"
                label="Your Email"
                variant="filled"
                className={classes.input}
                value={formData.email}
                onChange={setFormData}
                validators={['required', 'isEmail']}
                errorMessages={['Email is required', 'Email is not valid']}
              ></TextValidator>
            </Grid>
            <Grid item>
              <TextValidator
                name="subject"
                label="Subject"
                variant="filled"
                className={classes.input}
                value={formData.subject}
                onChange={setFormData}
                validators={['required']}
                errorMessages={['Subject is required']}
              ></TextValidator>
            </Grid>
            <Grid item>
              <TextValidator
                name="message"
                multiline
                label="Message"
                variant="filled"
                className={classes.textArea}
                value={formData.message}
                onChange={setFormData}
                validators={['required']}
                errorMessages={['Message is required']}
              ></TextValidator>
            </Grid>
          </Grid>
          <Grid item container justify="center" alignItems="center">
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={sending}
                className={classes.submitButton}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
    );
  }
};
