import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import splashImage from '../../images/backgrounds/hero-bg3.jpg';
import { H2sMitigation } from '../Home/H2sMitigation';
import { Anchor } from '../shared/Anchor';
import { Splash } from '../shared/Splash';
import { DataPortal } from './DataPortal';
import { ScadaServices } from './ScadaSolutions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

const Services: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Anchor name="services" />
      <Splash
        image={splashImage}
        anchor="#h2s-mitigation"
        header="Services & Products"
        subHeader=""
        buttonText="Learn More"
      />
      <H2sMitigation></H2sMitigation>
      <ScadaServices></ScadaServices>
      <DataPortal></DataPortal>
    </div>
  );
};

export default Services;
