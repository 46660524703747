import {
  Container,
  createStyles,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SideTitle from '../../shared/SideTitle';
import SideTitleItem from '../../shared/SideTitle/SideTitleItem';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: { padding: theme.spacing(3, 0, 0) },
    bullet: { padding: theme.spacing(0, 0, 0, 3), fontStyle: 'italic' },
  })
);

const Summary: React.FC = () => {
  const classes = useStyles();
  return (
    <SideTitleItem>
      <Container>
        <SideTitle title="H2S Mitigation" anchor="summary">
          <Grid item>
            <Typography variant="h4">Apcotane mitigates all H2S in oil permanently</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.body}>
              Apollo Petroleum Services revolutionary product, Apcotane, is designed to be an irreversible reaction.
              Once the reaction occurs between Apcotane and H2S, hydrogen sulfide is permanently mitigated, never to
              reappear downstream! Apcotane has been successfully tested at refining temperatures and on the frozen
              tundra of the Dakota’s.
            </Typography>
          </Grid>
          <Grid item>
            <List>
              {[
                'Apcotane has successfully mitigated up to 500,000 ppm of H2S in minutes.​',
                'Apcotane’s chemistry formulation is designed to allows for NO Fallout or precipitation, thus eliminating scaling issues.​',
                'The reaction does not create a “byproduct” that falls out of solution.',
              ].map(bullet => (
                <ListItem key={bullet} className={classes.bullet}>
                  <ListItemIcon>
                    <Icon>trending_flat</Icon>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>{bullet}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
        </SideTitle>
      </Container>
    </SideTitleItem>
  );
};
export default Summary;
