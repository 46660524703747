import { Button, Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Anchor } from '../Anchor';
import { FullWidthImage } from '../FullWidthImage';
import { SmoothScrollLink } from '../SmoothScrollLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    splashContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      width: '100vw',
    },
    header: {
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      textAlign: 'center',
      color: theme.palette.common.white,
    },
    subHeader: {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    companyName: {
      whiteSpace: 'nowrap',
    },
    splashButton: {
      margin: theme.spacing(4, 0, 0),
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
  })
);

interface ISplashProps {
  image: string;
  darken?: number;
  header: string;
  subHeader?: string;
  buttonText: string;
  anchor: string;
}

export const Splash: React.FC<ISplashProps> = ({ image, darken = 0.5, header, subHeader, buttonText, anchor }) => {
  const classes = useStyles();

  // when a Splash is used, this page will auto-scroll to the top on initial load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Anchor name="home" />
      <FullWidthImage image={image} darken={darken}>
        <Container className={classes.splashContainer}>
          <Typography variant="h3" className={classes.header}>
            {header}
          </Typography>
          {subHeader ? (
            <Typography variant="h5" className={classes.subHeader}>
              {subHeader}
            </Typography>
          ) : null}
          <SmoothScrollLink anchor={anchor}>
            <Button variant="outlined" size="large" className={classes.splashButton}>
              {buttonText.toUpperCase()}
            </Button>
          </SmoothScrollLink>
        </Container>
      </FullWidthImage>
    </>
  );
};
