import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import aboutImage from '../../../images/filler/hmi-01.png';
import { Anchor } from '../../shared/Anchor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      padding: theme.spacing(0, 0, 2, 0),
      textTransform: 'uppercase',
    },
    gridRoot: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 7, 0),
      },
    },
    graphicContainer: {
      textAlign: 'right',
    },
    graphic: {
      width: '100%',
      maxWidth: '400px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '1000px',
      },
    },
    description: {
      flexGrow: 1,
      color: theme.palette.common.white,
      padding: theme.spacing(0.5, 0, 0, 2),
    },
    descriptionTitle: {
      textTransform: 'uppercase',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  })
);

export const Hmi: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Anchor name="about-us" />
      <Container>
        <Grid container className={classes.gridRoot} spacing={2} alignItems="center" direction="row-reverse">
          <Grid item sm={12} md={6} className={classes.graphicContainer}>
            <img src={aboutImage} alt="about us" className={classes.graphic}></img>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container justify="center" spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4" className={classes.header}>
                  HMI
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography className={classes.description}>
                  Visualizations are key when your personnel are focused on optimizing safety, operations, and
                  profitability. By using state of the art software and properly sized HMI displays for your
                  application, the desired result provides a consistent interface for the end user. From highly mobile
                  applications to stationary facilities, <span className={classes.noWrap}>Tri Technology</span> can
                  provide you with the optimal choices and HMI integration.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
