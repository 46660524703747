import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 0, 0),
    },
  })
);

const SideTitleItem: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.root}>
      {children}
    </Grid>
  );
};
export default SideTitleItem;
