import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    anchor: {
      display: 'block',
      position: 'relative',
      top: `-${theme.spacing(15)}px`,
      visibility: 'hidden',
    },
  })
);

interface AnchorProps {
  name: string;
}

export const Anchor: React.FC<AnchorProps> = ({ name }) => {
  const classes = useStyles();
  return <div className={classes.anchor} id={name}></div>;
};
