import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { sections } from '../../../constants/page';

const useStyles = makeStyles({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
});
interface NavLinkProps {
  anchor: string;
}

const getSafeAnchor = (anchor: string) => `${anchor.replace(/ /g, '-').toLowerCase()}`;

export const SmoothScrollLink: React.FC<NavLinkProps> = ({ anchor, children }) => {
  const safeAnchor = getSafeAnchor(anchor);
  const [active, setActive] = useState(false);
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    const hash = location.hash ? location.hash : getSafeAnchor(sections[0].name);
    const isMatch = hash === safeAnchor;
    setActive(isMatch);
  }, [location, safeAnchor]);

  return (
    <NavHashLink smooth to={`${safeAnchor}`} isActive={() => active} className={classes.link}>
      {children}
    </NavHashLink>
  );
};
