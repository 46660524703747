import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import aboutImage from '../../../images/filler/pump-jack-02.png';
import { Anchor } from '../../shared/Anchor';
import { SmoothScrollLink } from '../../shared/SmoothScrollLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight: {
      color: theme.palette.primary.light,
    },
    list: {},
    listIcon: {
      color: theme.palette.secondary.main,
      fontWeight: 900,
      fontSize: theme.spacing(6),
    },
    listItem: { padding: theme.spacing(0) },
    listItemText: {
      // fontWeight: 900
    },
    ctaButton: {
      color: theme.palette.secondary.dark,
      margin: '0 auto',
      display: 'flex',
      fontSize: theme.spacing(3),
    },
    ctaButtonIcon: {
      paddingRight: theme.spacing(1),
      fontSize: theme.spacing(4),
    },

    header: {
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(0, 0, 2, 0),
      textTransform: 'uppercase',
      color: theme.palette.primary.dark,
      textAlign: 'center',
    },
    gridRoot: {
      flexGrow: 1,
    },
    tenetSection: {
      padding: theme.spacing(6),
    },
    tenet: {
      minHeight: theme.spacing(10),
      width: '100%',
    },
    graphicContainer: {
      textAlign: 'center',
    },
    graphic: {
      width: '100%',
      maxWidth: '600px',
    },
    description: {
      flexGrow: 1,
      padding: theme.spacing(0.5, 0, 0, 2),
    },
    descriptionTitle: {
      textTransform: 'uppercase',
    },
  })
);

export const H2sMitigation: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  // REMOVE APCOTANE
  // const history = useHistory();
  // const clickLearnMore = (e: React.MouseEvent) => {
  //   e.stopPropagation();
  //   // history.push(`/services/apcotane`);
  // };
  return (
    <>
      <Anchor name="h2s-mitigation" />
      <Box className={classes.tenetSection}>
        <Container>
          <Grid container className={classes.gridRoot} spacing={5} alignItems="center">
            <Grid item sm={12}>
              <Typography variant="h3" className={classes.header}>
                Eliminate your <span className={classes.highlight}>H2S</span>
                {/* with <span className={classes.highlight}>APCOTANE</span> */}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} className={classes.graphicContainer}>
              <img src={aboutImage} alt="APCOTANE" className={classes.graphic}></img>
            </Grid>
            <Grid item sm={12} md={6}>
              <Grid container justify="center" spacing={2}>
                <Grid item sm={12}>
                  <List className={classes.list}>
                    {[
                      'Irreversible',
                      'NO Fallout',
                      'Economically treat ANY level of H2S down to 0 ppm',
                      'Keep your personnel safe',
                    ].map(sellingPoint => (
                      <ListItem key={sellingPoint} className={classes.listItem}>
                        <ListItemIcon>
                          <Icon className={classes.listIcon}>check</Icon>
                        </ListItemIcon>
                        <ListItemText>
                          {isExtraSmall ? (
                            <Typography variant="h5" className={classes.listItemText}>
                              {sellingPoint}
                            </Typography>
                          ) : (
                            <Typography variant="h4" className={classes.listItemText}>
                              {sellingPoint}
                            </Typography>
                          )}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} alignItems="center">
              {/* REMOVE APCOTANE 
              <Button className={classes.ctaButton} onClick={clickLearnMore}> */}
              <SmoothScrollLink anchor="#contact-us">
                <Button className={classes.ctaButton}>
                  {/* REMOVE APCOTANE 
                  <Icon className={classes.ctaButtonIcon}>arrow_forward</Icon> Learn More */}
                  <Icon className={classes.ctaButtonIcon}>arrow_forward</Icon> Contact Us
                </Button>
              </SmoothScrollLink>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
