import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import aboutImage from '../../../images/filler/tech-02.jpg';
import { Anchor } from '../../shared/Anchor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      padding: theme.spacing(0, 0, 2, 0),
      textTransform: 'uppercase',
    },
    gridRoot: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 7, 0),
      },
    },
    graphicContainer: {
      textAlign: 'right',
    },
    graphic: {
      width: '100%',
      maxWidth: '400px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '1000px',
      },
    },
    description: {
      flexGrow: 1,
      color: theme.palette.common.white,
      padding: theme.spacing(0.5, 0, 0, 2),
    },
    descriptionTitle: {
      textTransform: 'uppercase',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  })
);

export const IntelliMix: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Anchor name="about-us" />
      <Container>
        <Grid container className={classes.gridRoot} spacing={2} alignItems="center" direction="row-reverse">
          <Grid item sm={12} md={6} className={classes.graphicContainer}>
            <img src={aboutImage} alt="about us" className={classes.graphic}></img>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container justify="center" spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4" className={classes.header}>
                  Intelli-Mix
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography className={classes.description}>
                  The INTELLI-MIX CHEM TRAILERS are leading the way in mobile automation and enhancements for the
                  blending and injection of today’s complex completion fluids. Scientifically engineered chemicals
                  demand quality equipment that can deliver excellence and precision in a level of execution that
                  repeatedly delivers when it matters most. Leveraging cutting edge remote technology, the blending
                  processes can be viewed and controlled remotely by the swipe of a switch.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
