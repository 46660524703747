import {
  Container,
  createStyles,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import image from '../../../images/filler/h2s-for-og.jpg';
import { FullWidthImage } from '../../shared/FullWidthImage';
import SideTitle from '../../shared/SideTitle';
import SideTitleItem from '../../shared/SideTitle/SideTitleItem';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { color: theme.palette.common.white },
    list: {},
    listIcon: {
      color: theme.palette.common.white,
    },
  })
);

const Benefits: React.FC = () => {
  const classes = useStyles();
  return (
    <SideTitleItem>
      <FullWidthImage image={image} darken={0.5}>
        <Container className={classes.root}>
          <SideTitle title="Benefits" anchor="benefits">
            <Grid item>
              <List className={classes.list}>
                {[
                  'Patented',
                  'Eliminate Hydrogen Sulfide',
                  'Extremely Efficient',
                  'Improve Safety',
                  'Increasingly Reduce Scale',
                  'Lower RVP',
                  'Reduce Mercaptan Sulfur',
                  'Inline or Batch Treatment',
                ].map(benefit => (
                  <ListItem key={benefit}>
                    <ListItemIcon>
                      <Icon className={classes.listIcon}>check</Icon>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="h6">{benefit}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </SideTitle>
        </Container>
      </FullWidthImage>
    </SideTitleItem>
  );
};
export default Benefits;
