import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import logoColor from '../../../images/branding/logo-color.png';
import logoWhite from '../../../images/branding/logo-white.png';

interface LogoProps {
  className: string;
  color?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoImg: {
      height: '100%',
    },
  })
);

export const Logo: React.FC<LogoProps> = ({ className, color = false }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <img alt="Home" src={color ? logoColor : logoWhite} className={classes.logoImg}></img>
    </div>
  );
};
