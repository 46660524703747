import { Box, CardActionArea } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MailIcon from '@material-ui/icons/Mail';
import React, { createRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconBox } from '../../shared/IconBox';
import { SmoothScrollLink } from '../../shared/SmoothScrollLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 0, 5),
      cursor: 'pointer',
    },
    cardContent: {
      padding: theme.spacing(0, 3),
    },
    card: {
      position: 'relative',
      overflow: 'visible',
    },
    title: {
      textAlign: 'center',
      color: theme.palette.grey[900],
      fontWeight: 700,
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(5, 0, 0),
      textTransform: 'uppercase',
    },
    titleHover: {
      color: theme.palette.grey[900],
    },
    description: {
      padding: theme.spacing(1, 0, 1),
    },
    icon: {
      position: 'absolute',
      top: '-36px',
      left: 'calc(50% - 42px)',
      zIndex: 5,
    },
    fauxButton: {
      fontWeight: 500,
    },
    contactUsLink: {
      width: '100%',
    },
  })
);

export interface ServiceBoxProps {
  iconName: string;
  title: string;
  description: string | JSX.Element;
  route: string;
}

export const ServiceBox: React.FC<ServiceBoxProps> = ({ iconName, title, description, route }) => {
  const [hover, setHover] = useState(false);
  const classes = useStyles();
  const cardLinkRef = createRef<HTMLDivElement>();

  const hasPage = route[0] === '/';

  const gridClick = () => cardLinkRef.current?.click();

  return (
    <Grid
      item
      md={4}
      sm={6}
      xs={12}
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={gridClick}
    >
      <Card className={classes.card}>
        <IconBox iconName={iconName} className={classes.icon} hover={hover} />
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" className={`${classes.title} ${hover && classes.titleHover}`}>
              {title}
            </Typography>
            <Typography paragraph className={classes.description}>
              {description}
            </Typography>
          </CardContent>
          <CardActions>
            <LinkToRouteOrAnchor route={route} hasPage={hasPage} ref={cardLinkRef} />
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

interface ILinkToRouteOrAnchorProps {
  route: string;
  hasPage: boolean;
}

const LinkToRouteOrAnchor = React.forwardRef(
  ({ hasPage, route }: ILinkToRouteOrAnchorProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const history = useHistory();
    const pageNavClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      history.push(`${route}`);
    };
    return hasPage ? (
      <Grid container spacing={1} onClick={pageNavClick} ref={ref}>
        <Grid item>
          <ArrowForwardIcon />
        </Grid>
        <Grid item>
          <Typography className={classes.fauxButton}>Learn More</Typography>
        </Grid>
      </Grid>
    ) : (
      <Box className={classes.contactUsLink}>
        <SmoothScrollLink anchor={route}>
          <Grid container spacing={1} ref={ref} className={classes.contactUsLink}>
            <Grid item>
              <MailIcon />
            </Grid>
            <Grid item>
              <Typography className={classes.fauxButton}>Contact Us</Typography>
            </Grid>
          </Grid>
        </SmoothScrollLink>
      </Box>
    );
  }
);
