import { AppBar, Hidden, IconButton, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import useWindowScroll from '@react-hook/window-scroll';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sections } from '../../constants/page';
import { Logo } from '../shared/Logo';
import { SmoothScrollLink } from '../shared/SmoothScrollLink';
import { SideDrawer } from '../SideDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: 'rgba(0, 0, 0, .7)',
      flexGrow: 1,
      height: theme.spacing(15),
      transition: 'height 1s',
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(8),
      },
    },
    menuButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.common.white,
    },
    logo: {
      padding: theme.spacing(2),
      flexGrow: 1,
      height: theme.spacing(12),
      transition: 'height 1s',
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(6),
      },
    },
    navLinks: {
      paddingRight: theme.spacing(1),
      display: 'flex',
      '& a': {
        padding: theme.spacing(0, 0, 0, 3),
      },
      '& .active': {
        textDecoration: 'underline',
      },
    },
    navLinkText: {
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      textDecoration: 'none',
    },
  })
);

export const TopNav: React.FC = () => {
  const scrollY = useWindowScroll(60);
  const showSmall = scrollY > 400;
  const classes = useStyles({ showSmall });
  const [open, setOpen] = useState(false);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Logo className={classes.logo}></Logo>
        <Hidden mdUp>
          <IconButton
            onClick={() => setOpen(!open)}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <SideDrawer open={open} setOpen={setOpen}></SideDrawer>
        </Hidden>
        <Hidden smDown>
          <div className={classes.navLinks}>
            {sections.map(({ name, anchorOrPage, path }) =>
              anchorOrPage === 'anchor' ? (
                <SmoothScrollLink key={name} anchor={path || name}>
                  <Typography className={classes.navLinkText}>{name}</Typography>
                </SmoothScrollLink>
              ) : (
                <Link key={name} to={path || `/${name}`}>
                  <Typography className={classes.navLinkText}>{name}</Typography>
                </Link>
              )
            )}
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
