// export const sections = ['Home', 'About Us', 'Services', 'Products', 'Contact Us'];
interface ISiteSection {
  name: string;
  anchorOrPage?: 'anchor' | 'page';
  path?: string;
}

export const sections: ISiteSection[] = [
  {
    name: 'Home',
    anchorOrPage: 'anchor',
    path: '/#home',
  },
  {
    name: 'Services',
    anchorOrPage: 'anchor',
    path: '/#services',
  },
  // REMOVE APCOTANE
  // { name: 'Apcotane', anchorOrPage: 'anchor', path: '/services/apcotane' },
  {
    name: 'Contact Us',
    anchorOrPage: 'anchor',
    path: '/#contact-us',
  },
];
