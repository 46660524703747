import { Box, Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import aboutImage from '../../../images/filler/tech-01.jpg';
import { Anchor } from '../../shared/Anchor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(0, 0, 2, 0),
      textTransform: 'uppercase',
    },
    gridRoot: {
      flexGrow: 1,
    },
    tenetSection: {
      padding: theme.spacing(6),
    },
    tenet: {
      minHeight: theme.spacing(10),
      width: '100%',
    },
    graphicContainer: {
      textAlign: 'right',
    },
    graphic: {
      width: '100%',
      maxWidth: '600px',
    },
    description: {
      flexGrow: 1,
      padding: theme.spacing(0.5, 0, 0, 2),
    },
    descriptionTitle: {
      textTransform: 'uppercase',
    },
  })
);

export const DataPortal: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Anchor name="data-portal" />
      <Box className={classes.tenetSection}>
        <Container>
          <Grid container className={classes.gridRoot} spacing={2} alignItems="center" direction="row-reverse">
            <Grid item sm={12} md={6} className={classes.graphicContainer}>
              <img src={aboutImage} alt="about us" className={classes.graphic}></img>
            </Grid>
            <Grid item sm={12} md={6}>
              <Grid container justify="center" spacing={2}>
                <Grid item sm={12}>
                  <Typography variant="h4" className={classes.header}>
                    Data Portal
                  </Typography>
                </Grid>
                <Grid item sm={12}>
                  <Typography>
                    Tri Technology can collect and host your data for easy access on desktop or mobile. If you need to
                    integrate our data storage with an existing SCADA solution, we also offer an API. Contact us for
                    more details.
                    <br />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
