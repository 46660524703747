import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import image from '../../../images/filler/scada-01.jpg';
import { Anchor } from '../../shared/Anchor';
import { FullWidthImage } from '../../shared/FullWidthImage';
import { Hmi } from './Hmi';
import { IntelliMix } from './IntelliMix';
import { PlcControls } from './PlcControls';
import { Sensors } from './Sensors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(15, 3),
    },
    gridRoot: {
      width: '100%',
    },
    title: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
    },
    description: {
      color: theme.palette.common.white,
    },
    quoteMe: {
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
  })
);

export const ScadaServices: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Anchor name="request" />
      <FullWidthImage image={image} darken={0.8}>
        <Container className={classes.container}>
          <Grid item xs={12} container direction="column" spacing={3} className={classes.gridRoot}>
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                SCADA Services
              </Typography>
            </Grid>
            <Grid item>
              <PlcControls></PlcControls>
              <Hmi></Hmi>
              <Sensors></Sensors>
              <IntelliMix></IntelliMix>
            </Grid>
          </Grid>
        </Container>
      </FullWidthImage>
    </>
  );
};
