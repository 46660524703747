import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import aboutImage from '../../../images/filler/plc-01.jpeg';
import { Anchor } from '../../shared/Anchor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      padding: theme.spacing(0, 0, 2, 0),
      textTransform: 'uppercase',
    },
    gridRoot: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 7, 0),
      },
    },
    graphicContainer: {
      textAlign: 'right',
    },
    graphic: {
      width: '100%',
      maxWidth: '400px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '1000px',
      },
    },
    description: {
      flexGrow: 1,
      color: theme.palette.common.white,
      padding: theme.spacing(0.5, 0, 0, 2),
    },
    descriptionTitle: {
      textTransform: 'uppercase',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  })
);

export const PlcControls: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Anchor name="about-us" />
      <Container>
        <Grid container className={classes.gridRoot} spacing={2} alignItems="center" direction="row-reverse">
          <Grid item sm={12} md={6} className={classes.graphicContainer}>
            <img src={aboutImage} alt="about us" className={classes.graphic}></img>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container justify="center" spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h4" className={classes.header}>
                  Automation Controls
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography className={classes.description}>
                  Today's aggressive Oil & Gas market requires your business to partner with capable and
                  service-oriented organizations that put you in control of your field assets. Tri Technology supports
                  the latest in automation controls, leveraging many different PLC, RTU, and edge device manufacturers.
                  From system design and programming to fabrication and deployment,
                  <span className={classes.noWrap}> Tri Technology’s</span> speed, price, and delivery are unbeatable.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
