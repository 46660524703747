import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface FullWidthImageProps {
  children?: React.ReactNode;
  image: string;
  darken?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageBox: {
      backgroundImage: (props: FullWidthImageProps) => `url(${props.image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      [theme.breakpoints.up('md')]: {
        backgroundAttachment: 'fixed',
      },
    },
    darken: {
      height: '100%',
      width: '100%',
      backgroundColor: (props: FullWidthImageProps) => `rgba(0, 0, 0, ${props.darken})`,
    },
  })
);

export const FullWidthImage: React.FC<FullWidthImageProps> = ({ children, image, darken }) => {
  const classes = useStyles({ image, darken });

  return (
    <Box className={classes.imageBox}>
      <Box className={classes.darken}>{children}</Box>
    </Box>
  );
};
