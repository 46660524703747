import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Anchor } from '../Anchor';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(5, 0) },
    header: {
      padding: theme.spacing(0, 1, 1, 0),
      // color: theme.palette.secondary.main,
    },
    content: {},
  })
);

interface ISideTitleProps {
  title: string;
  anchor?: string;
}

const SideTitle: React.FC<ISideTitleProps> = ({ title, anchor, children }) => {
  const classes = useStyles();
  return (
    <>
      {anchor ? <Anchor name={anchor} /> : null}
      <Grid container direction="row" className={classes.root}>
        <Grid item sm={12} md={3} className={classes.header}>
          <Title>{title}</Title>
        </Grid>
        <Grid item className={classes.content} sm={12} md={9}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

const Title: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid>
      <Grid item className={classes.header}>
        <Typography variant="h3">{children}</Typography>
      </Grid>
    </Grid>
  );
};

export default SideTitle;
