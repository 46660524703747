import { createStyles, Grid, Icon, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      flexGrow: 1,
    },
  })
);

const contactInfo = [
  {
    // iconName: 'room',
    // description: ['Some where', 'Broussard, LA 70518'],
    // description: 'Broussard, LA 70518',
  },
  {
    iconName: 'mail',
    description: ['P.O.Box 549', 'Maurice, LA 70555'],
  },
  {
    iconName: 'computer',
    description: 'info@tritechgo.com',
  },
  {
    iconName: 'phone',
    description: '+1 832.377.1834',
  },
];

export const Location: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container item sm={12} md={4} direction="column" spacing={3}>
      {contactInfo.map((info, index) => (
        <Grid key={index} container item direction="row" spacing={2}>
          <Grid item>
            <Icon color="primary">{info.iconName}</Icon>
          </Grid>
          <Grid item>
            {Array.isArray(info.description) ? (
              info.description.map((desc, i) => <Typography key={i}>{desc}</Typography>)
            ) : (
              <Typography>{info.description}</Typography>
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item className={classes.spacer}></Grid>
    </Grid>
  );
};
