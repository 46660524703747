import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Apcotane from './components/Apcotane';
import Home from './components/Home/Home';
import Scada from './components/Scada';
import Services from './components/Services';
import { TopNav } from './components/TopNav';
// /* Color Theme Swatches in Hex */
// $tech-blue-and-green-1-hex: #022C66;
// $tech-blue-and-green-2-hex: #0466BF;
// $tech-blue-and-green-3-hex: #039FDA;
// $tech-blue-and-green-4-hex: #05C148;
// $tech-blue-and-green-5-hex: #017129;

// /* Color Theme Swatches in RGBA */
// $tech-blue-and-green-1-rgba: rgba(2,43,102, 1);
// $tech-blue-and-green-2-rgba: rgba(4,102,191, 1);
// $tech-blue-and-green-3-rgba: rgba(2,158,218, 1);
// $tech-blue-and-green-4-rgba: rgba(4,193,72, 1);
// $tech-blue-and-green-5-rgba: rgba(1,112,40, 1);

// /* Color Theme Swatches in HSLA */
// $tech-blue-and-green-1-hsla: hsla(214, 96, 20, 1);
// $tech-blue-and-green-2-hsla: hsla(208, 95, 38, 1);
// $tech-blue-and-green-3-hsla: hsla(196, 97, 43, 1);
// $tech-blue-and-green-4-hsla: hsla(141, 94, 38, 1);
// $tech-blue-and-green-5-hsla: hsla(141, 98, 22, 1);
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0466BF',
    },
    secondary: {
      main: '#05C148',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TopNav />
        <Switch>
          <Route path="/services/apcotane" component={Apcotane} />
          <Route path="/services/scada" component={Scada} />
          <Route path={['/services', '/services/:route']} component={Services} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
