import { Container, Grid, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { Anchor } from '../../shared/Anchor';
import { ContactForm } from './ContactForm';
import { Location } from './Location';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(12, 3),
    },
    header: {
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(0, 0, 2, 0),
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    description: {
      textAlign: 'center',
      paddingBottom: theme.spacing(5),
      color: theme.palette.grey[600],
    },
    gridRoot: {},
  })
);

export const Contact: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Anchor name="contact-us" />
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.header}>
          Contact
        </Typography>
        <Typography className={classes.description}>We would love to hear from you!</Typography>
        <Grid container className={classes.gridRoot}>
          <Grid item md={1} lg={2} />
          <Grid container item sm={12} md={10} lg={8}>
            <Location />
            <ContactForm />
          </Grid>
          <Grid item md={1} lg={2} />
        </Grid>
      </Container>
    </>
  );
};
