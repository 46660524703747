import { Drawer, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { sections } from '../../constants/page';
import { SmoothScrollLink } from '../shared/SmoothScrollLink';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
});

interface SlideDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const SideDrawer: React.FC<SlideDrawerProps> = ({ open = false, setOpen }) => {
  const classes = useStyles();
  const closeDrawer = () => setOpen(false);
  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer}>
      <div className={classes.list} role="presentation" onClick={closeDrawer}>
        <List>
          {sections.map(({ name, anchorOrPage, path }, index) =>
            anchorOrPage === 'anchor' ? (
              <SmoothScrollLink key={name} anchor={path || name}>
                <ListItem button>
                  <ListItemText>{name}</ListItemText>
                </ListItem>
              </SmoothScrollLink>
            ) : (
              <Link key={name} to={path || `/${name}`} className={classes.link}>
                <ListItem button>
                  <ListItemText>{name}</ListItemText>
                </ListItem>
              </Link>
            )
          )}
        </List>
      </div>
    </Drawer>
  );
};
