import { Container, GridList, GridListTile, makeStyles } from '@material-ui/core';
import React from 'react';
import { default as hmiImage, default as splashImage } from '../../images/backgrounds/hero-bg3.jpg';
import plcImage from '../../images/filler/plc-gears.png';
import intelliMixImage from '../../images/filler/pump-jack-sunset.jpg';
import sensorsImage from '../../images/filler/sensors-01.jpg';
import integrationImage from '../../images/filler/tech-02.jpg';
import { Splash } from '../shared/Splash';
import ScadaCard, { ScadaOffering } from './ScadaCard';

// const Scada: React.FC = () => {
//     return (
//         <>
//             <Splash
//                 image={splashImage}
//                 anchor="Scada"
//                 header="Control System Engineering​"
//                 subHeader="Today's aggressive Oil & Gas market requires your business
//                 to partner with capable and service-oriented organizations that put you in control of your field assets.
//                 From new installs to upgrading and retrofitting existing infrastructure, Tri Technology provides design,
//                 programming, fabrication, and implementation for controlling your complete facility. "
//                 buttonText="Learn more"
//             />
//             <Grid container direction="column">
//                 <Grid item>
//                     <Typography variant="h1">PLC</Typography>
//                     <Typography variant="h2">Programmable Logic Controller​</Typography>
//                 </Grid>
//                 <Grid item>

//                 </Grid>
//             </Grid>
//         </>
//     );
// };

// const triTechnology = (possessive?: boolean) =>
//   possessive ? (
//     <span style={{ whiteSpace: 'nowrap' }}>Tri Technology's</span>
//   ) : (
//     <span style={{ whiteSpace: 'nowrap' }}>Tri Technology</span>
//   );

const scadaOfferings: ScadaOffering[] = [
  {
    image: plcImage,
    title: 'PLC',
    subtitle: 'Programmable Logic Controller',
    content:
      'Tri Technology supports the latest in automation controls, leveraging many different PLC, RTU, and edge device manufacturers.\n\nOur solutions provide remote access and control for any endpoint device or peripheral; pumps, valves, motors, actuators, and many, many more! We ensure our clients have what they require, and uptimes that are second to none.\n\nFrom system design and programming to fabrication and deployment, Tri Technology’s ​speed, price, and deliveries are unbeatable.',
  },
  {
    image: hmiImage,
    title: 'HMI',
    subtitle: 'Human Machine Interface',
    content:
      'Visualizations are key when your personnel are focused on optimizing safety, operations, and profitability. By using state of the art software and properly sized HMI displays for your application,  the desired result provides a consistent interface for the end user. From highly mobile applications to stationary facilities,  Tri Technology can provide you with the optimal choice in HMI integration.',
  },
  {
    image: integrationImage,
    title: 'Control System Integration',
    subtitle: '',
    content:
      'Tri Technology works with our clients and partners, utilizing the latest advances in technology, to create the most cost-effective systems while increasing production and improving quality.\n\nUsing the latest HMI and PLC programming techniques, we provide our clients with greater results and end user satisfaction.\n\nContact us to learn more about our programming options but using the Contact Us.',
  },
  {
    image: sensorsImage,
    title: 'Sensors',
    subtitle: '',
    content:
      "From flow meters monitoring 1000's bbls/day, to temperature probes detecting the slightest degree of change, Tri Technology is committed to providing our customers the best available sensors for the application.\n\nWe provide any peripheral your job requires; pumps, flow meters, pressure sensors, thermocouples, fluid level monitoring sensors, bulk tank sensors, wave radar, analog or digital, and much more. Contact us today to discuss your next project.​",
  },
  {
    image: intelliMixImage,
    title: 'Intelli-Mix​',
    subtitle: '',
    content:
      'The INTELLI-MIX CHEM TRAILERS are leading the way in mobile automation and edge communication for the blending and injection of today’s complex completion fluids.\n\nScientifically engineered chemicals demand quality equipment to deliver excellence and precision at a level of execution that repeatedly delivers when it matters most.\n\nLeveraging cutting edge remote technology, the blending processes can be viewed and controlled remotely by the flip of a switch or swipe of a mobile device application.',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    width: 500,
    height: 450,
  },
}));

export const Scada: React.FC = () => {
  const classes = useStyles();
  const allBoxes = scadaOfferings.map(x => (
    <ScadaCard key={x.title} image={x.image} title={x.title} subtitle={x.subtitle} content={x.content} />
  ));
  return (
    <>
      <Splash
        image={splashImage}
        anchor="Scada"
        header="Control System Engineering​"
        subHeader="Today's aggressive Oil & Gas market requires your business to partner with capable and service-oriented organizations that put you in control of your field assets. From new installs to upgrading and retrofitting existing infrastructure, Tri Technology provides design, programming, fabrication, and implementation for controlling your complete facility. "
        buttonText="Learn more"
      />
      <Container>
        <div className={classes.root}>
          <GridList cellHeight={180} className={classes.gridList}>
            <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}></GridListTile>
            {allBoxes}
          </GridList>
        </div>
      </Container>
    </>
  );
};

export default Scada;
