import React from 'react';
import splashImage from '../../images/backgrounds/hero-bg.jpg';
import { Splash } from '../shared/Splash';
import { Contact } from './Contact';
import { H2sMitigation } from './H2sMitigation';
// import { Request } from './Request';
import { Services } from './Services';
// import { Tenets } from './Tenets';

const Home: React.FC = () => {
  return (
    <>
      <Splash
        image={splashImage}
        anchor="#h2s-mitigation"
        header="Welcome To Tri Technology"
        subHeader="Your Innovation, Software and Service Provider"
        buttonText="View our services"
      />
      {/* <Tenets /> */}
      <H2sMitigation />
      <Services />
      {/* <Request /> */}
      <Contact />
    </>
  );
};

export default Home;
