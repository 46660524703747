import {
  Container,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import SideTitle from '../../shared/SideTitle';
import SideTitleItem from '../../shared/SideTitle/SideTitleItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    faq: {
      width: '100%',
    },
    faqGrid: {},
    faqHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    faqHeaderContent: {
      fontSize: `1.15rem`,
      fontWeight: 500,
    },
  })
);

interface IFaqListProps {
  title: string;
}

/**
 * TODO
 * Uses the same "raw text as children prop"
 * pattern as CaseStudies, but I think it's a mistake here
 */
const FAQs: React.FC = () => {
  return (
    <>
      <FaqList title="Frequently Asked Questions">
        <Faq>
          <FaqHeader>What happens to the sulfur?</FaqHeader>
          <FaqContent>
            The Hydrogen Sulfide (H2S) is disassociated due to a PH neutralization. When one of the Hydrogen molecules
            separates it shifts the Sulfur molecule out of the Sulfide phase. Then the HS- and H- molecule is bound to
            an organic acid that is present in the Apcotane product. This reaction ties up the sulfur molecule not
            allowing it to revert back to the sulfide phase (making reaction irreversible). Since the organic acid is
            Carbon based essentially the sulfur molecule is attached to a carbon chain. This allows for there to be no
            harm or deviation in the refining process.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>What does it do to my oil – integrity of the hydrocarbon?</FaqHeader>
          <FaqContent>
            The integrity of the hydrocarbon is intact. In fact, previous testing has shown improvement in the light
            hydrocarbon chain.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is it exothermic?</FaqHeader>
          <FaqContent>
            It is non-exothermic reaction, therefore it can be used to treat inorganic sulfides (e.g. iron sulfide)
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is it pyrophoric?</FaqHeader>
          <FaqContent>
            It is a non-exothermic reaction, therefore it can be used to treat inorganic suflides (e.g. iron sulfide)
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Can it be shipped in bulk?</FaqHeader>
          <FaqContent>Product can be shipped via rail, truck, or ship.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is it a scavenger?</FaqHeader>
          <FaqContent>
            Apcotane is not a scavenger. The performance of Apcotane is based on stream dependency not molar reaction or
            bacteria consumption. Unlike a scavenger we treat H2S ONE time to eliminate it – it does not create
            precipitants – and it is not corrosive to metals.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is it corrosive to metals?</FaqHeader>
          <FaqContent>
            It is not corrosive to metals and has an irreversible reaction with hydrogen sulfide. The reactive complexes
            are a neutral PH. Based on SGS testing it is not corrosive to metals, and we can provide this 3rd party
            testing.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is the treated crude pipeline grade?</FaqHeader>
          <FaqContent>
            Yes, Apcotane improves the quality of the oil mitigating sulfide gases and neutralizing the hydrocarbon
            acids.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is it refinery grade?</FaqHeader>
          <FaqContent>
            We have 3rd party testing by SGS verifying our product meets spec for refinery grade. We also have a full
            crude assay to verify we do not alter the fractional distillation cuts of the oil.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Does it change the API?</FaqHeader>
          <FaqContent>
            There could be very minimal changes to API. All oil is different but prior testing shows API could be
            adjusted less than 1%.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Do we adjust the PH of the oil?</FaqHeader>
          <FaqContent>
            One very beneficial thing is the PH is brought to a neutral state after the Apcotane is used. Apcotane
            neutralizes the hydrocarbon acid complexes (organic and inorganic acid complexes).
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is this product readily accessible?</FaqHeader>
          <FaqContent>The product is readily available in mass quantities.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Is the viscosity of the oil changed?</FaqHeader>
          <FaqContent>
            The viscosity and density of the oil experiences a minor alteration based on the displacement of the acid
            gases. Thus reducing the Reid Vapor Pressure (RVP).
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>How does it compare to caustic treatment?</FaqHeader>
          <FaqContent>
            Our Apcotane dosed correctly does not have any precipitants out of the oil. Unlike caustic that will cause
            precipitation which can also lead to scaling in pipes, our Apcotane will keep everything in solution. #2 H2S
            will not come back once the PH is adjusting or changed once it is treated with Apcotane. Caustic does not
            destroy hydrogen sulfide - once the treated stream is mixed with other lower pH streams the oil is
            neutralized and hydrogen sulfide is released.
          </FaqContent>
        </Faq>
      </FaqList>
      <FaqList title="Advanced FAQs">
        <Faq>
          <FaqHeader>
            What kind of loading is required for different levels of H2S (H2S reduction vs Apcotane concentration) and
            how sensitive is this curve to the type of medium (different types of crude oil, or even looking at
            expanding to other fluids, e.g. brines)?
          </FaqHeader>
          <FaqContent>
            Apcotane has been used on products containing 1-200,000 ppm of vapor phase H2S. It has been able to
            successfully mitigate the vapor phase H2S as well as the soluble H2S. Apcotane dosage rate is stream
            dependent not ppm dependent. Since our products reaction starts by allowing the PH to increase to a neutral
            state, there has to be enough dosage for this event to start.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            What is the nature of the reaction product(s) that is(are) formed from Apcotane + H2S, and can it(they) be
            quantified to get a mass balance of the sulfur?
          </FaqHeader>
          <FaqContent>
            Apcotane is a patented technology. It can be quantified to get mass balance of sulfur. Our chemistry is
            built around increasing the PH in the water vapor and the organics which will allow the H2S to disassociate.
            This will also take the sulfur out of the sulfide phase. Then a secondary reaction takes place by combining
            an organic acid with the HS - molecule.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            Can Apcotane concentration in solution be monitored continuously along with the reaction product(s)?
          </FaqHeader>
          <FaqContent>Yes it can be monitored continuously.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Are there any safety or health issues with respect to handling and storing Apcotane?</FaqHeader>
          <FaqContent>
            Apcotane falls under federal, state, and OSHA guidelines along with company safety regulations. Our SDS also
            addresses chemical handling and required PPE. (Click Link to see our SDS)
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            Do we have chart showing different areas of application of Apcotane at Upstream &amp; Downstream, for
            example which is the best location of chemical dosing in Upstream and Downstream application and results
            observed.
          </FaqHeader>
          <FaqContent>
            Upstream – Generally after the water separator.
            <br />
            Midstream – In tanks at gathering location.
            <br />
            Downstream – Typically in a tank farm or continuous flow.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            What happens to the H2S after dosing Apcotane, where does it go in Oil &amp; Water applications.
          </FaqHeader>
          <FaqContent>Apcotane is applied after the three-phase separator to the oil.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>What is the effect of Apcotane in Oil Production with 5% - 20% water cut.</FaqHeader>
          <FaqContent>Ideally, we would separate out the water.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            On which parameters the dosing rate of Apcotane dependent on, is it universally the same or it depends on
            the H2S content, Water % and other parameters.
          </FaqHeader>
          <FaqContent>
            There is a slight increase based on H2S ppm and the API of the oil. Ideally we would like to separate out
            the water, because the molecular structure of the two elements are not the same base, and the solubility of
            water is greater than that of oil.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            Does the dosing require special training or special equipment’s/ pumps, or existing dosing pumps and local
            technicians are able conduct the dosing.
          </FaqHeader>
          <FaqContent>
            We would test a sample at a testing facility to pre-run a sample to verify dosage rate in the field. As far
            as application it is handled as basic chemistry. We also utilize standard compatible chemical pumps.
          </FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>Any special storage facility / ambient temperatures required for Apcotane?</FaqHeader>
          <FaqContent>Above 50 degrees F. Keep out of direct sunlight.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>What is the shelf life of Apcotane?</FaqHeader>
          <FaqContent>5 Years – Closed Container.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            Apcotane is exported in Liquid form or powder form, if powder form what is the usage procedure?
          </FaqHeader>
          <FaqContent>Liquid form.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>
            Does Apcotane dosing require any additional chemical or diesel blending before dosing or is it preblended
            and ready for dosing upon arrival at customer facility?
          </FaqHeader>
          <FaqContent>Pre-blended.</FaqContent>
        </Faq>
        <Faq>
          <FaqHeader>What is the pour point of Apcotane blended in with the oil?</FaqHeader>
          <FaqContent>Our solution has been used in North Dakota with -15º F</FaqContent>
        </Faq>
      </FaqList>
    </>
  );
};

const FaqList: React.FC<IFaqListProps> = ({ children, title }) => {
  const classes = useStyles();
  return (
    <SideTitleItem>
      <Container>
        <SideTitle title={title} anchor="faqs">
          <Grid item container className={classes.faqGrid} spacing={2}>
            {children}
          </Grid>
        </SideTitle>
      </Container>
    </SideTitleItem>
  );
};

const Faq: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.faq}>
      <ExpansionPanel>{children}</ExpansionPanel>
    </Grid>
  );
};

const FaqHeader: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <ExpansionPanelSummary className={classes.faqHeader} expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.faqHeaderContent}>{children}</Typography>
    </ExpansionPanelSummary>
  );
};

const FaqContent: React.FC = ({ children }) => {
  return (
    <ExpansionPanelDetails>
      <Typography>{children}</Typography>
    </ExpansionPanelDetails>
  );
};

export default FAQs;
