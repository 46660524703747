import {
  Card,
  CardContent,
  CardMedia,
  Container,
  createStyles,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import image4 from '../../../images/backgrounds/hero-bg5.jpg';
import image2 from '../../../images/filler/man-shadow.jpg';
import image3 from '../../../images/filler/pipeline-valve.png';
import image1 from '../../../images/filler/refinery.jpg';
import SideTitle from '../../shared/SideTitle';
import SideTitleItem from '../../shared/SideTitle/SideTitleItem';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    csNumber: {
      color: theme.palette.grey[500],
    },
    bullet: {
      padding: theme.spacing(0),
    },
    bulletIcon: {
      margin: theme.spacing(0),
      minWidth: 'auto',
    },
    bulletHighlight: {
      // color: red[600],
      fontWeight: 600,
    },
  })
);

/**
 * This section uses raw text sent through the children props
 * because certain bullets contain span'd text formatting
 */
const CaseStudies: React.FC = () => {
  const classes = useStyles();
  return (
    <SideTitleItem>
      <Container>
        <SideTitle title="Case Studies" anchor="case-studies">
          <CaseStudyList>
            {/* TODO  */}
            <CaseStudy image={image1} title="Thing">
              <CaseStudyNumber>Case Study 1101</CaseStudyNumber>
              <CaseStudyTitle>Eliminates H2S & obtains pipeline approval</CaseStudyTitle>
              <CaseStudyBullets>
                <CaseStudyBullet>Bakken Formation in North Dakota​</CaseStudyBullet>
                <CaseStudyBullet>
                  H2S = 40,000 ppm that was <span className={classes.bulletHighlight}> mitigated to 0 ppm​</span>
                </CaseStudyBullet>
                <CaseStudyBullet>Introduced target direct injection at the wellhead​</CaseStudyBullet>
                <CaseStudyBullet>Eliminate H2S in 30 min residence time</CaseStudyBullet>
                <CaseStudyBullet>Added static mixers in tanks for optimization​</CaseStudyBullet>
                <CaseStudyBullet>Producer decides to batch treat in tanks​</CaseStudyBullet>
                <CaseStudyBullet>
                  <span className={classes.bulletHighlight}>Reduced dosage treatment by 50%​</span>
                </CaseStudyBullet>
                <CaseStudyBullet>Client is treating 1,000-3,500 bbls/day​</CaseStudyBullet>
                <CaseStudyBullet>Performing excellent for over 1 year​</CaseStudyBullet>
              </CaseStudyBullets>
            </CaseStudy>
            {/* TODO  */}
            <CaseStudy image={image2} title="Thing">
              <CaseStudyNumber>Case Study 1015</CaseStudyNumber>
              <CaseStudyTitle>Say "Yes" to a SOUR portfolio</CaseStudyTitle>
              <CaseStudyBullets>
                <CaseStudyBullet>Eagleford Formation in South Texas​</CaseStudyBullet>
                <CaseStudyBullet>
                  H2S = 50,000 ppm that was <span className={classes.bulletHighlight}>mitigated to 0 ppm​</span>
                </CaseStudyBullet>
                <CaseStudyBullet>Introduced batch treatment process in tanks​</CaseStudyBullet>
                <CaseStudyBullet>Minimal Capital Expenditures for process​</CaseStudyBullet>
                <CaseStudyBullet>Residence time was varied from 2 to 24 hours​</CaseStudyBullet>
                <CaseStudyBullet>
                  <span className={classes.bulletHighlight}>24 hour residence decreased dosage by 50%​​</span>
                </CaseStudyBullet>
                <CaseStudyBullet>Client is treating 1,000 bbls/day​</CaseStudyBullet>
                <CaseStudyBullet>Apcotane greatly impacts inactive production​</CaseStudyBullet>
                <CaseStudyBullet>Revolutionize the sour portfolio wells & fields​</CaseStudyBullet>
              </CaseStudyBullets>
            </CaseStudy>
            {/* TODO  */}
            <CaseStudy image={image3} title="Thing">
              <CaseStudyNumber>Case Study 1028​</CaseStudyNumber>
              <CaseStudyTitle>Production continues w/o risks</CaseStudyTitle>
              <CaseStudyBullets>
                <CaseStudyBullet>Eagleford Formation in South Texas​</CaseStudyBullet>
                <CaseStudyBullet>
                  H2S = 20,000 ppm that was <span className={classes.bulletHighlight}>mitigated to 0 ppm</span>​
                </CaseStudyBullet>
                <CaseStudyBullet>Lower treating cost & Improve efficiency​</CaseStudyBullet>
                <CaseStudyBullet>Provide direct injection with existing pumps​</CaseStudyBullet>
                <CaseStudyBullet>Producer decides to batch treat in tanks​</CaseStudyBullet>
                <CaseStudyBullet>Comparison of Triazine vs Apcotane™​</CaseStudyBullet>
                <CaseStudyBullet>
                  Triazine <span className={classes.bulletHighlight}>0.1% dosage</span> reduced H2S to{' '}
                  <span className={classes.bulletHighlight}>300 ppm</span>​
                </CaseStudyBullet>
                <CaseStudyBullet>
                  Apcotane™ <span className={classes.bulletHighlight}>0.05% dosage</span> reduced H2S to{' '}
                  <span className={classes.bulletHighlight}>0 ppm</span>
                </CaseStudyBullet>
                <CaseStudyBullet>
                  Mitigated H2S residence time to 6 hours w/o any further capital expenditures​
                </CaseStudyBullet>
              </CaseStudyBullets>
            </CaseStudy>
            {/* TODO  */}
            <CaseStudy image={image4} title="Thing">
              <CaseStudyNumber>Case study 1032​</CaseStudyNumber>
              <CaseStudyTitle>Transformed the Bottom-line</CaseStudyTitle>
              <CaseStudyBullets>
                <CaseStudyBullet>Permian Basin in West Texas​</CaseStudyBullet>
                <CaseStudyBullet>
                  H2S = 125,000+ ppm that was <span className={classes.bulletHighlight}>mitigated to 0 ppm</span>​
                </CaseStudyBullet>
                <CaseStudyBullet>Introduced batch treatment process in tanks​</CaseStudyBullet>
                <CaseStudyBullet>Well shut in prior to Apcotane™ solution​</CaseStudyBullet>
                <CaseStudyBullet>Mitigated H2S in a residence time of 8 hours​</CaseStudyBullet>
                <CaseStudyBullet>Reduce liability of loading/unloading trucks</CaseStudyBullet>
                <CaseStudyBullet>
                  <span className={classes.bulletHighlight}>Irreversible reaction with no by-products​</span>
                </CaseStudyBullet>
                <CaseStudyBullet>Client is treating 3,500 bbls/day​</CaseStudyBullet>
                <CaseStudyBullet>Revolutionize the sour portfolio wells & fields​</CaseStudyBullet>
              </CaseStudyBullets>
            </CaseStudy>
          </CaseStudyList>
        </SideTitle>
      </Container>
    </SideTitleItem>
  );
};

const CaseStudyList: React.FC = ({ children }) => (
  <Grid item container spacing={3}>
    {children}
  </Grid>
);

interface ICaseStudyProps {
  title: string;
  image: string;
}

const CaseStudy: React.FC<ICaseStudyProps> = ({ title, image, children }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Card>
        <CardMedia component="img" alt={title} height="140" image={image} title={title} />
        <CardContent>{children}</CardContent>
      </Card>
    </Grid>
  );
};

const CaseStudyNumber: React.FC = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.csNumber}>{children}</Typography>;
};

const CaseStudyTitle: React.FC = ({ children }) => {
  return <Typography variant="h5">{children}</Typography>;
};

const CaseStudyBullets: React.FC = ({ children }) => {
  return <List dense>{children}</List>;
};

const CaseStudyBullet: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.bullet}>
      <ListItemIcon className={classes.bulletIcon}>
        <Icon>keyboard_arrow_right</Icon>
      </ListItemIcon>
      <ListItemText>
        <Typography>{children}</Typography>
      </ListItemText>
    </ListItem>
  );
};

export default CaseStudies;
