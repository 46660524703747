import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import image from '../../../images/filler/scada-01.jpg';
import { Anchor } from '../../shared/Anchor';
import { FullWidthImage } from '../../shared/FullWidthImage';
import { ServiceBox } from './ServiceBox';

const triTechnology = (possessive?: boolean) =>
  possessive ? (
    <span style={{ whiteSpace: 'nowrap' }}>Tri Technology's</span>
  ) : (
    <span style={{ whiteSpace: 'nowrap' }}>Tri Technology</span>
  );

const services = [
  {
    title: 'IOT / Cloud',
    route: '#contact-us',
    // route: '/services/iot',
    iconName: 'cloud_queue',
    description: (
      <>
        IOT is pushing edge computing further than ever. {triTechnology()} is your partner for intelligent design,
        powerful architecture, and scalable resources for any size organization.
      </>
    ),
  },
  {
    title: 'H2S Chemical Mitigation',
    route: '#contact-us',
    // route: '/services/apcotane',
    iconName: 'done_outline',
    description: (
      <>
        H2S souring your profits? {triTechnology(true)} Mitigation blend economically treats from 5ppm to more than
        500,000ppm. Eliminate H2S and sweeten your bottom line.
      </>
    ),
  },
  {
    title: 'Control Systems Engineering',
    route: '#contact-us',
    // route: '/services/control-systems',
    iconName: 'games',
    description:
      'Industrial automation for optimum performance and a robust sytem that resolves your most complex operational challenges. Engineering you can trust.',
  },
  {
    title: 'Field Implementation & Deployment',
    route: '#contact-us',
    iconName: 'settings_remote',
    description: (
      <>
        {triTechnology(true)} field implementation is leading the way in automation and scalability. On-site control
        when and where it matters
      </>
    ),
  },
  {
    title: 'Post Implementation Support & Maintenance',
    route: '#contact-us',
    iconName: 'tune',
    description: (
      <>
        Upon project completion, your long term success is found in world class support that you can rely on.{' '}
        {triTechnology()} is here for you
      </>
    ),
  },
  {
    title: 'Project Management',
    route: '#contact-us',
    iconName: 'assignment',
    description: (
      <>
        {triTechnology()} specializes in planning, organizing, and deploying your project initiatives. We measure the
        things that matter to you and the future of your company.
      </>
    ),
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    servicesGrid: {
      marginBottom: '1em',
      padding: theme.spacing(7, 0),
    },
    header: {
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(0, 0, 2, 0),
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    sectionDescription: {
      textAlign: 'center',
      paddingBottom: '40px',
      color: theme.palette.common.white,
    },
  })
);

export const Services: React.FC = () => {
  const classes = useStyles();
  const allBoxes = services.map(x => (
    <ServiceBox key={x.title} iconName={x.iconName} title={x.title} description={x.description} route={x.route} />
  ));
  return (
    <>
      <Anchor name="services" />
      <FullWidthImage image={image} darken={0.5}>
        <Container>
          <Grid container spacing={5} className={classes.servicesGrid}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.header}>
                Services & Products
              </Typography>
              <Typography className={classes.sectionDescription}>
                Serving with the Spirit of Excellence You Deserve and We Require.
              </Typography>
            </Grid>
            {allBoxes}
          </Grid>
        </Container>
      </FullWidthImage>
    </>
  );
};
