import {
  Card,
  CardMedia,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  GridListTile,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

export interface ScadaOffering {
  image: string;
  title: string;
  subtitle: string;
  content: string; //JSX.Element;
}
const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
  },
}));
const ScadaCard: React.FC<ScadaOffering> = ({ image, title, subtitle, content }) => {
  const classes = useStyles();
  //const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <GridListTile>
      <Card className={classes.root}>
        <CardMedia component="img" height="140" image={image} title="Live from space album cover" />
        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography component="h5" variant="h5">
              {title}{' '}
              <Typography style={{ display: 'inline-block' }} variant="subtitle1" color="textSecondary">
                {subtitle}
              </Typography>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography style={{ whiteSpace: 'pre-line' }} variant="body2" color="textSecondary" component="p">
              {content}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>
    </GridListTile>
  );
};

export default ScadaCard;
